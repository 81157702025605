.fit-min {
  min-width: min-content;
}

.center {
  text-align: center;
}

.place-center {
  place-self: center;
  text-align: center;
}

.t-left {
  text-align: left;
}

.v-center {
  align-self: center;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.m-left {
  margin-left: 1rem;
}

.m-right {
  margin-right: 1rem;
}