// Breakpoints
$desktop: 1200px;
$tablet: 768px;
$mobile: 425px;

// App favorites
$primary: #ffc45d;
$secondary: rgb(62, 62, 62);
$saber-yellow: $primary;
$saber-grey: #0f0f0f;
$saber-grey-80: rgba($saber-grey, 0.80);
$saber-dark: #080808;

$faded: #101010;
$faded-15: rgba($faded, 0.15);
$faded-25: rgba($faded, 0.25);
$faded-50: rgba($faded, 0.5);
$faded-60: rgba($faded, 0.6);
$faded-70: rgba($faded, 0.7);
$faded-80: rgba($faded, 0.8);
$faded-90: rgba($faded, 0.9);

// Standard definitions
$white: #ffffff;
$black: #000000;
$black-70: rgba($black, 0.7);
$black-80: rgba($black, 0.8);
$black-90: rgba($black, 0.9);
$transparent: #00000000;

// Backgrounds
$body-bg: #ffffff;

// Borders
$border-radius: 1rem;