html {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: none;
}

body {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  background-color: none;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: none;
}