@import "init";
@import "utility";
@import "variables";
@import "icons";

// globally set scroll bar style
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(#ffe07b, 1);
}

.appcontainer {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  background-color: $saber-dark;
}

.overlay {
  .panel {
    position: fixed;
    top: 56px;
    left: 0;
    width: 100%;
    background-color: $black-70;
    color: $white;
    box-shadow: 0 0 88px 0 #000000;
    z-index: 4;
    transition: all 0.3s ease;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .btn-base {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0.75rem;
    height: 40px;
    width: 40px;
    padding: 0 !important;
    background-color: rgba(16, 16, 16, 0.9);
    z-index: 500;
    transition: all 0.3s ease;
    cursor: pointer;
    border-radius: $border-radius;
  }

  .sat-btn-0 {
    bottom: calc(120px);
    border: 2px solid rgba(#ffffff, 1);
  }

  .sat-btn-1 {
    bottom: calc(120px);
    border: 2px solid rgba(#ffe07b, 1);
  }

  .constellation-grid-btn-disabled {
    bottom: calc(220px);
    border: 2px solid rgba(#ffffff, 1);
  }

  .constellation-grid-btn-enabled {
    bottom: calc(220px);
    border: 2px solid rgba(#ffe07b, 1);
  }

  .constellation-btn-disabled {
    bottom: calc(170px);
    border: 2px solid rgba(#ffffff, 1);
  }

  .constellation-btn-enabled {
    bottom: calc(170px);
    border: 2px solid rgba(#ffe07b, 1);
  }

  .gt-btn-0 {
    bottom: calc(70px);
    border: 2px solid rgba(#ffffff, 1);
  }

  .gt-btn-1 {
    bottom: calc(70px);
    border: 2px solid rgba(#ffe07b, 1);
  }

  .mute-btn {
    bottom: calc(20px);
    border: 2px solid rgba(#ffffff, 1);
  }

  .un-mute-btn {
    bottom: calc(20px);
    border: 2px solid rgba(#ffe07b, 1);
  }

  .darkTable {
    background-color: rgba(27, 27, 27, 0.2);
    color: white;
  }

  img.icon-sat {
    content: $sat;
    background-color: black
  }

  img.icon-const {
    content: $constellation;
    background-color: black
  }

  img.icon-cards {
    content: $cards;
    background-color: black
  }

  .icon-ticket {
    background-image: $ticket;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .icon-battery {
    background-image: $battery;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  img.icon-table {
    content: $table;
    background-color: black
  }

  img.icon-mute {
    content: $mute;
    background-color: black;
  }

  img.icon-un-mute {
    content: $un_mute;
    background-color: black;
  }

  img.icon-sun {
    content: $sun;
  }

  img.icon-eclipse {
    content: $eclipse;
  }

  img.icon-entering-eclipse {
    content: $entering_eclipse;
  }

  img.icon-exiting-eclipse {
    content: $exiting_eclipse;
  }

  .groundstation-table-container {
    display: flex;
    justify-content: center;
    padding: 1rem 0rem;
    height: 200px;
    overflow-y: scroll;
  }

  .groundstation-checkbox-container {
    display: flex;
    justify-content: center;
  }

  .constellation-grid-container {
    display: flex;
    justify-content: center;
    // padding: 1rem 0rem;
    height: 100%;
    overflow-y: scroll;
    padding: 2rem 4vw 0 4vw;
  }

  .groundstation-table {
    flex-grow: 0.3;
    font-size: smaller;

    thead {
      padding: 0.5rem 0;
      border-bottom: 1px solid $white;

      th {
        padding: 0 0.5rem 0.5rem 0.5rem;
        text-align: center;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $white;

        td {
          padding: 0.5rem 0.25rem;
          text-align: center;

          &:first-of-type {
            padding-left: 0;
          }

          &:last-of-type {
            padding-right: 0;
          }
        }
      }
    }

    @media screen and (max-width: $tablet) {
      width: 100%;
      flex-grow: 0;
    }
  }
}

.alerts {
  position: fixed;
  z-index: 10;
  background-color: $saber-grey-80;
  color: $white;
  border-radius: $border-radius;
}

.spaceview {
  background-color: $black;
  color: $white;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.loginscreen {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  color: $white;
  padding: 10%;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 10000;
}

.loginform {
  max-width: 420px;
  padding: 2rem;
}

.login-logo {
  margin-top: -2rem;
  margin-bottom: 1.5rem;
  -webkit-filter: drop-shadow(0px 0px 7px rgba(#ffe07b, 0.1));
  filter: drop-shadow(0px 0px 7px rgba(#ffe07b, 0.1));
}

.clock {
  margin-right: 10px;
  margin-left: 10px;
  font-weight: bold;
}

.topbar {
  background-color: #272727;
  color: $white;
  z-index: 100;

  .logo {
    margin-left: 1rem;
  }

  .mobile-logo {
    margin-left: 1rem;
    padding-bottom: 5px;
    width: 32px;
    height: 32px;
  }

  .satelliteSelect {
    background: rgb(62, 62, 62) !important;
    color: white !important;
    display: flex;
    justify-content: center;
    -webkit-text-align: center !important;
    text-align: center !important;
  }

  .satellite-menu {
    margin-right: 1rem;
  }
}

.satmenu {
  display: block;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 100;
  height: 50vh;
  min-height: 500px;
  width: calc(100% - 2rem) !important;
  font-size: smaller;
  max-width: 480px;
  color: $white;
  background-color: $faded-70;
  margin: calc(56px + 1rem) 1rem 1rem 1rem;
  border-radius: $border-radius;
  box-shadow: 0 0 6px 0 #303030;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.offcanvas-backdrop {
  z-index: 50 !important;
  cursor: pointer !important;
}

.toaster {
  z-index: 50000 !important;
}

.my-tooltip-leftmaxwidth > .tooltip-inner {
  max-width: 100%;
  text-align: left;
}

// This import must be at the bottom of the file, otherwise it overrides the above styles
@import "../../../node_modules/bootstrap/scss/bootstrap";